@use "sass:math";

$black: #000000;
$border: #000000;
$background: #FFFFFF;
$white: #FFFFFF;
$highlight: #663886;
$backgroundHighlight: #CDC5BC;
$cta: #CDC5BC;
$sale: #FF0000;
$error: #FF0000;


$font: 'EB Garamond', Baskerville, Baskerville Old Face, Hoefler Text, Times New Roman, serif;
$headline: Circular, Futura, Trebuchet MS, Arial, sans-serif;
$otherlands: Lateral, sans-serif;

// Grid
$totalGridWidthPx: (45 * 12) + (12 * 11);
$gridColumns: 12;
$gut: 12px;
$gut600: 12px;
$col: math.div(100, 12);

$pageWidth: $totalGridWidthPx * 1px;

@mixin resp($media) {
	@if $media == max600 {
		@media only screen and (max-width: 599px) { @content; }
	} @else if $media == min600 {
		@media only screen and (min-width: 600px) { @content; }
	} @else if $media == max750 {
		@media only screen and (max-width: 749px) { @content; }
	} @else if $media == min750 {
		@media only screen and (min-width: 750px) { @content; }
	} @else if $media == max900 {
		@media only screen and (max-width: 899px) { @content; }
	} @else if $media == min900 {
		@media only screen and (min-width: 900px) { @content; }
	} @else if $media == max1000 {
		@media only screen and (max-width: 999px) { @content; }
	} @else if $media == min1000 {
		@media only screen and (min-width: 1000px) { @content; }
	} @else if $media == max1200 {
		@media only screen and (max-width: 1159px) { @content; }
	} @else if $media == min1200 {
		@media only screen and (min-width: 1200px) { @content; }
	}
}

@mixin chevronDown($pos: after) { 
	@if $pos == before {
		&:before { content: ""; width: 8px; height: 8px; border-bottom: 1.5px solid; border-right: 1.5px solid; transform: rotateZ(45deg); margin: 3px 0 0 10px; display: inline-block; vertical-align: top; }
	} @else {
		&:after { content: ""; width: 8px; height: 8px; border-bottom: 1.5px solid; border-right: 1.5px solid; transform: rotateZ(45deg); margin: 3px 0 0 10px; display: inline-block; vertical-align: top; }
	}
}

@mixin grid($total, $col, $gut, $colColor, $gutColor) {
  background-image: repeating-linear-gradient(
    90deg,
    $gutColor,
    $gutColor $gut,
    $colColor $gut,
    $colColor #{100% / $gridColumns}
  );
  @include resp(min600) {
    background-image: repeating-linear-gradient(
      90deg,
      $gutColor,
      $gutColor $gut600,
      $colColor $gut600,
      $colColor #{100% / $gridColumns}
    );
  }
}

@function strip-unit($num) {
	@return $num / ($num * 0 + 1);
}

@function w($num, $resp: 0, $multiple: 1) {
	$percent: math.div(100, 12) * $num * 1%;
	$less: 12 * $multiple;

	@return calc(#{$percent} - #{$less}px);
}

@mixin pageWidth() { width: calc(100% - 30px); margin: 0 auto;
  @include resp(min600) { width: calc(100% - 100px); }
}

@mixin pageWidthGrid() { width: 100%; position: relative; margin: 0 auto;
  @include resp(min600) { width: calc(100% + #{$gut}); left: -$gut; }
}

@mixin logoPositionGrid() { position: absolute; left: 0;
	@include resp(minPageWidth) { left: calc(50% - #{($pageWidth / 2) + $gut600}); }
}

@mixin functionsPositionGrid() { position: absolute; right: 0;
	@include resp(min600) { right: 7px; }
	@include resp(minPageWidth) { right: calc(50% - #{($pageWidth / 2) + 13}); }
}

@mixin fz($size:body) {
	@if $size == 24 { font-size: 24px; line-height: 36px; } // H1, H2, H3
	@if $size == 18 { font-size: 18px; line-height: 26px; } // H4, H5
	@if $size == 14 { font-size: 14px; line-height: 21px; } // H4, H5
	@if $size == 12 { font-size: 12px; line-height: 21px; } // H4, H5
	@if $size == body { font-size: 14px; line-height: normal; } // Body
	@if $size == menu { font-size: 14px; line-height: normal; } // Menu

	@if $size == 16 { font-size: 12px; line-height: 1.5em; }
	@if $size == 24 { font-size: 16px; line-height: 1.5em; }
	@if $size == 36 { font-size: 24px; line-height: 1.5em; }
	@if $size == 54 { font-size: 36px; line-height: 1.5em; }
	@if $size == 72 { font-size: 36px; line-height: 1.5em; }

	@include resp(min600) {
		@if $size == 16 { font-size: 16px; line-height: 1.5em; }
		@if $size == 24 { font-size: 24px; line-height: 1.5em; }
		@if $size == 36 { font-size: 36px; line-height: 1.5em; }
		@if $size == 54 { font-size: 54px; line-height: 1.5em; }
		@if $size == 72 { font-size: 72px; line-height: 1.5em; }
	}
}

@mixin headline() { font-family: $headline; font-weight: 700; letter-spacing: 0em; margin: 0; }
@mixin body() { font-family: $font; @include fz(body); }
@mixin menu() { font-family: $headline; @include fz(menu); }
@mixin subtitle() { font-family: $font; @include fz(body); font-weight: 700; letter-spacing: 0em; text-transform: uppercase; }

.slider { position: relative; display: flex; flex-direction: column; user-select: none; padding: 0; box-sizing: border-box;
	&_inner { overflow: hidden; padding-bottom: 60px; width: 100%; }
	&_slides { display: flex; 
		&__transition {
			transition: transform 0.25s;
		}
		> * { flex-basis: 0; flex-grow: 1; }
	}
	&_nav { display: flex; flex-flow: row wrap; margin-top: -55px; order: 2; justify-content: center; }
	&_navButton { background: none; border: 0; opacity: 0.3; outline: none; position: relative; transition: opacity 0.25s; padding: 8px; cursor: pointer;
		&::after { content: ''; background: $black; border-radius: 50%; display: block; height: 6px; width: 6px; }
		&__active { opacity: 1;
			&::after { opacity: 1; }
		}
	}
	&_arrow { position: absolute; z-index: 1; background: none; border: none; opacity: 0.5; outline: none; position: absolute; transition: opacity 0.25s; padding: 0px; width: 25%; height: calc(100% - 28px); cursor: pointer;
		:global(.no-touchevents) &:hover { opacity: 1; }
		&::before { content: ''; display: block; width: 24px; height: 24px; border-top: 2px solid $black; border-right: 2px solid $black; margin: 0 auto; position: absolute; right: 5px; }
		&__left { top: 0; left: 0; margin-left: 20px;
			&::before { transform: rotate(225deg); right: auto; left: 5px; }
		}
		&__right { top: 0; right: 0; margin-right: 20px;
			&::before { transform: rotate(45deg); }
		}
	}
	@include resp(max600) { align-items: center;
		&_arrow { display: none; }
	}
	&__product { display: inline-flex; vertical-align: top; width: 100%; overflow: hidden;
		.slider {
			&_inner { width: 100%; height: 100%; padding-bottom: 0; }
			&_thumbs { overflow: hidden;
				&_inner { width: 300%; padding: 10px 0; box-sizing: border-box; transform: translateX(0); transition: transform 0.25s;
					&_thumb { width: calc(20vw - 16px); margin: 0 10px 10px 0; display: inline-block; vertical-align: top; border: none; }
				}
			}
			&_arrow { width: 40%; height: 100%;
				&__left { margin-left: 0; }
			}
			@include resp(min600) {
				&_inner { width: calc(100% - 80px); margin-left: 30px; }
				&_thumbs { width: calc(100% - 80px); margin-left: 30px;
					&_inner {
						&_thumb { width: calc(10.4vw - 27.5px); }
					}
				}
			}
			@include resp(min900) {
				&_inner { width: calc(80% - 80px); }
				&_thumbs { position: absolute; top: 0; right: 50px; width: calc(20% - 50px); height: 100%;
					&_inner { width: 100%; padding: 0; height: 100%; position: relative; transform: translateY(0);
						&_thumb { width: auto; margin: 0 0 15px; display: block; border-bottom: none; 
							&:last-child { margin-bottom: 0; }
						}
					}
					&:after { content: ""; position: absolute; bottom: 0; z-index: 10; width: 100%; height: 150px; background-image: linear-gradient(to bottom, rgba(#fff, 0), #fff); pointer-events: none; }
					&__scrolled:before { content: ""; position: absolute; top: 0; z-index: 10; width: 100%; height: 150px; background-image: linear-gradient(to bottom, #fff, rgba(#fff, 0)); pointer-events: none; }
				}
				&_arrow__right { right: 20%; }
			}
		}
	}
}